import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";
import GofflineLogo from '../../../images/logos/goffline_no_bg.svg'
import React, {useEffect, useState} from 'react';
import {useFetch} from 'use-http';
import {Button, ProgressBar, Spinner} from 'react-bootstrap';

import {toast} from 'react-toastify';

const Header = props => {
    const [taskId, setTaskId] = useState(null);
    const [taskState, setTaskState] = useState("");
    const [progress, setProgress] = useState(0);

    const {get, post, response} = useFetch({cachePolicy: 'no-cache'});

    const startImport = async (e) => {
        e.preventDefault();
        handleDismiss();
        const data = await post('/activities/import/start/');
        if (response.ok) {
            setTaskId(data.task_id);
            checkImportStatus();
            if (data.nextcloud.status !== "ok") {
                toast(data.nextcloud.message, {type: data.nextcloud.status});
            }
        }

    };

    const handleDismiss = () => {
        setTaskId(null);
        setTaskState("");
        setProgress(0);
    }

    const checkImportStatus = async () => {
        if (!taskId) return;
        const data = await get(`/activities/import/status/${taskId}`);
        if (response.ok) {
            setProgress((data.complete / data.total) * 100);
            setTaskState(data.import_state)
            if (!data.import_state) return
            if (data.import_state === "PROGRESS") return
            if (data.import_state === "SUCCESS") {
                toast.success("Import Complete!", {});
            }
            if (data.import_state === "ERROR") {
                toast.error("An error occurred!", {});
            }
            props.triggerRefresh();
            handleDismiss();
        }
    };


    useEffect(() => {
        let interval;
        checkImportStatus();
        if (taskState === "PROGRESS") {
            interval = setInterval(() => {
                checkImportStatus();
            }, 1000);
        } else if (interval) {
            clearInterval(interval);
        }
        return () => {
            {
                clearInterval(interval);
            }
        }
    }, [taskId, taskState]);


    return (
        <div>
            <Navbar bg="primary" data-bs-theme="dark" expand="sm" style={{paddingRight: 10, paddingLeft: 10}}>
                <Navbar.Brand as={Link} to="/">
                    <GofflineLogo
                        width="130"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto mb-2 mb-sm-0">
                        <Nav.Link className={"active"} as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/settings">Settings</Nav.Link>
                        <Button
                            className={"border-0"}
                            variant="outline-warning"
                            disabled={taskState === "PROGRESS"}
                            onClick={!taskId ? startImport : null}
                        >
                            {taskState === "PROGRESS" && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                            {taskState === "PROGRESS" ? ' Importing' : 'Import Activities'}
                        </Button>
                        <Button
                            className={"border-0"}
                            variant="outline-success"
                            onClick={props.handleOpenModal}
                        >
                            Enter Health
                        </Button>
                    </Nav>
                    <Button
                        variant="outline-secondary"
                        component={Link}
                        href={"/accounts/logout/"}
                    >Log Out</Button>
                </Navbar.Collapse>
            </Navbar>
            {taskState === "PROGRESS" && <ProgressBar now={progress} label={`${progress.toFixed()}%`}/>}
        </div>
    )
}

export default Header;
