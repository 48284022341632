import React, {useEffect, useState} from 'react';
import HeatMap from '@uiw/react-heat-map';

const ActivitiesHeatMap = props => {
    const [startDate, setStartDate] = useState(getStartDate());
    const [width, setWidth] = useState(700);


    function getStartDate() {
        let today = new Date();
        let curMonth = today.getMonth()
        let curYear = today.getFullYear()
        return new Date(curYear - 1, curMonth, 1)
    }


    useEffect(() => {
        const handleResize = () => {
            const maxDays = 7 * 50
            const maxWidth = 740
            const newStartDate = new Date();
            let newWidth = window.innerWidth - 135
            newWidth = newWidth > maxWidth ? maxWidth : newWidth

            let daysToSubtract = Math.floor(newWidth - 100) / 13 * 7 // 13px per week
            daysToSubtract = daysToSubtract > maxDays ? maxDays : daysToSubtract // don't go back more than 70 weeks
            newStartDate.setDate(startDate.getDate() - daysToSubtract);

            setStartDate(newStartDate);
            setWidth(newWidth);
        };

        handleResize(); // Initial call

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (props.loading || props.data === undefined || props.data.length === 0) return <div/>
    if (props.data.length === 0) return <div>No Data</div>

    let activities = props.data
    // let startDate = getStartDate()
    activities = activities.map(item => {
        return {
            date: item.display_date.replaceAll("-", "/"),  // rename oldName to newName
            count: 1  // add newProp and set its value to 1
        };
    });
    return (
        <HeatMap
            value={activities}
            width={width}
            legendCellSize={0}
            weekLabels={['', 'Mon', '', 'Wed', '', 'Fri', '']}
            startDate={startDate}
        />
    );
};

export default ActivitiesHeatMap;
