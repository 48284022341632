import React, {useEffect, useState} from 'react';
import {useFetch} from 'use-http';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';

function LoadingPlaceHolder() {
    return (
        <>
            <p aria-hidden="true">
                <Placeholder xs={6}/>
            </p>

            <Placeholder.Button xs={4} aria-hidden="true"/>
        </>
    );
}

const OwnCloudForm = () => {
    const FORM_DEFAULT_STATE = {public_link: '', password: ''}
    const [input, setInput] = useState(FORM_DEFAULT_STATE);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const {get, post, del, response, error, loading} = useFetch();

    useEffect(() => {
        const fetchData = async () => {
            const data = await get('/connectors/owncloud');
            if (response.ok) {
                setInput({public_link: data.public_link, password: ""});
                if (data.public_link !== '') setDeleteDisabled(false);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await post('/connectors/owncloud', input);
        setDeleteDisabled(false);
    };
    const handleDelete = async (e) => {
        e.preventDefault();
        await del('/connectors/owncloud');
        setDeleteDisabled(true);
        setInput(FORM_DEFAULT_STATE);
    };

    if (loading) return <LoadingPlaceHolder/>;

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Public Link</Form.Label>
                <Form.Control
                    type="url"
                    placeholder="Enter public link address"
                    value={input.public_link}
                    onChange={e => setInput({...input, public_link: e.target.value})}
                />
                <Form.Text className="text-muted">
                    Enter public, password protected NextCloud link.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder="Password"
                    value={input.password}
                    onChange={e => setInput({...input, password: e.target.value})}

                />
            </Form.Group>
            <Row xs="auto">
                <Col>
                    <Button variant="primary" type="submit" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Col>
                <Col>
                    <Button variant="danger" type="submit" onClick={handleDelete} disabled={deleteDisabled}>
                        Delete
                    </Button>

                </Col>
            </Row>
            {error && <div>Error: {error.message}</div>}
        </Form>
    );
}

const SettingsPage = () => (
    <div>
        <h1>Settings</h1>
        <hr/>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>NextCloud</Accordion.Header>
                <Accordion.Body>
                    <OwnCloudForm/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
);

export default SettingsPage;
