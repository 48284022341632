import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {useFetch} from 'use-http';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {MapContainer, Polyline, TileLayer} from 'react-leaflet'
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import Table from 'react-bootstrap/Table';

const MatchedActivitiesTable = props => {
    let data = props.data.matched_runs
    const rows = data.map(row => <tr>
        <td>{row.display_date}</td>
        <td>{row.display_heart_rate}</td>
        <td>{row.display_duration}</td>
        <td>{row.display_pace}</td>
        <td>
            {props.activityId === row.id ? <></> : <Link to={"/activity/" + row.id}>View</Link>}
        </td>


    </tr>)
    return (
        <Table striped hover>
            <thead>
            <tr>
                <th>Date</th>
                <th>Heart-Rate</th>
                <th>Duration</th>
                <th>Pace</th>
                <th>Details</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    )
}

const ActivityChart = (props) => {

    const formatXAxis = (x, showSeconds) => {
        let d = new Date(x)
        let dStr = d.toTimeString().split(' ')[0]
        if (showSeconds === false) return dStr.slice(0, 5)
        return dStr
    }
    return (
        <div style={{width: '100%'}}>
            <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                    width={500}
                    height={200}
                    data={props.data.tracks}
                    syncId="anyId"
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={"#5e19b5"} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={"#5e19b5"} stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey={"duration_dt"}
                        tickFormatter={(tick) => formatXAxis(tick, false)}
                    />
                    <YAxis reversed={props.invertYAxis}/>
                    <Tooltip
                        labelFormatter={(label) => formatXAxis(label)}
                    />
                    <Area type="monotone" dataKey={props.dataKey} stroke={"#fd9426"} fillOpacity={1}
                          fill="url(#colorPv)"/>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}
const ActivityMap = (props) => {
    let tracks = props.data.tracks
    tracks = tracks
        .filter(obj => obj.position_long !== null)
        .map(obj => [obj.position_lat, obj.position_long]);

    let avgLat = tracks.reduce((sum, val) => sum + val[0], 0) / tracks.length;
    let avgLong = tracks.reduce((sum, val) => sum + val[1], 0) / tracks.length;
    let startPosition = [avgLat, avgLong];


    if (tracks.length === 0) return <div>No Map data available for Activity</div>

    return (
        <MapContainer center={startPosition} zoom={13} scrollWheelZoom={false}
                      style={{width: "100%", height: 300}}>
            <TileLayer
                attribution=''
                url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
            />
            <Polyline pathOptions={{color: "#5e19b5"}} positions={tracks}/>
        </MapContainer>
    )

}
const ActivityCard = (props) => {
    return (
        <Card className={"border-primary bg-secondary mb-3"}>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                    {props.stat} {props.unit}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
const ActivityHeader = (props) => {
    return (
        <Row xs={1} sm={2} md={4} className="g-4">
            <Col>
                <ActivityCard title={"Location"} stat={props.data.display_location}/>
            </Col>
            <Col>
                <ActivityCard title={"Duration"} stat={props.data.display_duration} unit={"hrs"}/>
            </Col>
            <Col>
                <ActivityCard title={"Heart-Rate"} stat={props.data.display_heart_rate} unit={"BPM"}/>
            </Col>
            <Col>
                <ActivityCard title={"Pace"} stat={props.data.display_pace} unit={"min/km"}/>
            </Col>
        </Row>
    )
}


const SectionCard = (props) => {
    return (
        <Card className="border-0">
            <Card.Header>{props.header}</Card.Header>
            <Card.Body>
                {props.children}
            </Card.Body>
        </Card>
    )
}
const ActivityPage = () => {
        const {get, post, response, error, loading} = useFetch({cachePolicy: 'no-cache'});
        const [data, setActivityData] = useState([])
        let {activityId} = useParams();

        useEffect(() => {
            loadActivityData()
        }, [activityId]) // componentDidMount

        async function loadActivityData() {
            const data = await get("/activities/" + activityId)
            if (response.ok) setActivityData(data)
        }


        if (loading || data.length === 0) return <div>Loading...</div>;

        return (
            <div>
                <h1>{data.display_date} {data.display_location !== "Not available" ? " - " + data.display_location : ""} </h1>
                <Row className={"gy-5"}>
                    <Col xs={12}>
                        <ActivityHeader data={data}/>
                    </Col>
                    <Col xs={12}>
                        <SectionCard header={"Details"}>
                            <ActivityChart data={data}
                                           dataKey={"heart_rate"}
                                           invertYAxis={false}
                                           strokeColor={"#fd9426"}
                                           fillColor={"#5e19b5"}
                            />
                            <ActivityChart data={data}
                                           dataKey={"pace"}
                                           invertYAxis={true} f
                                           strokeColor={"#fd9426"}
                                           fillColor={"#fd9426"}
                            />
                        </SectionCard>
                    </Col>
                    <Col xs={12}>
                        <SectionCard header={"Matched Activities"}>
                            <MatchedActivitiesTable activityId={activityId} data={data}/>
                        </SectionCard>
                    </Col>
                    <Col xs={12}>
                        <SectionCard header={"Map"}>
                            <ActivityMap data={data}/>
                        </SectionCard>
                    </Col>

                </Row>
            </div>
        );

    }
;

export default ActivityPage;
