import React, {useState} from 'react';
import {Container} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import Header from '../components/header';
import HealthModal from "../components/healthModal";

const Layout = props => {
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);
    return (
        <div>
            <Header triggerRefresh={props.triggerRefresh} handleOpenModal={handleOpenModal}/>
            <Container fluid className="p-3">
                <ToastContainer/>
                <HealthModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    triggerRefresh={props.triggerRefresh}
                />
                <Row>
                    <Col/>
                    <Col xs={12} sm={10}>
                        {props.children}
                    </Col>
                    <Col/>
                </Row>
            </Container>
        </div>
    );
};
export default Layout;
