import {Link} from "react-router-dom";
import React from 'react';
import Table from 'react-bootstrap/Table';
import Placeholder from 'react-bootstrap/Placeholder';


function TablePlacHolder() {
    return (
        <>
            <Placeholder as="p" animation="wave">
                <Placeholder xs={6}/>
                <Placeholder className="w-75"/> <Placeholder style={{width: '25%'}}/>
                <Placeholder xs={6}/>
                <Placeholder className="w-75"/> <Placeholder style={{width: '25%'}}/>
            </Placeholder>
        </>
    )
        ;
}


const ActivitiesTable = props => {
    if (props.loading) return <TablePlacHolder/>

    const data = props.data.slice(0, 10)

    const rows = data.map(row =>
        <tr key={row.id}>
            <td>{row.display_date}</td>
            <td>{row.display_location}</td>
            <td>{row.display_distance}</td>
            <td>{row.display_duration}</td>
            <td><Link to={"/activity/" + row.id}>View</Link></td>
        </tr>
    )


    return (
        <Table striped hover>
            <thead>
            <tr>
                <th>Date</th>
                <th>Location</th>
                <th>Distance</th>
                <th>Duration</th>
                <th>Details</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    );
};

export default ActivitiesTable;
