import React, {useState} from 'react';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import SettingsPage from "./pages/settings";
import HomePage from "./pages/home";
import ActivityPage from "./pages/activity";
import {Provider} from 'use-http'

import NotFound from './pages/notfound404';
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./pages/layout";

const API_ROOT = process.env.API_ROOT;

function App() {
    const [refreshData, setRefreshData] = useState(false)

    const triggerRefresh = () => {
        setRefreshData(!refreshData);
    }
    const httpProviderOptions = {
        headers: {
            'X-CSRFTOKEN': Cookies.get('csrftoken'),
            mode: 'same-origin',
        }
    }
    return (
        <Provider url={API_ROOT} options={httpProviderOptions}>
            <BrowserRouter>
                <Layout
                    triggerRefresh={triggerRefresh}
                >
                    <Routes>
                        <Route path="settings" element={<SettingsPage/>}/>
                        <Route path="/" element={<HomePage refreshData={refreshData}/>}/>
                        <Route path="/activity/:activityId" element={<ActivityPage/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </Layout>
            </BrowserRouter>
        </Provider>
    )
        ;
}

export default App;
