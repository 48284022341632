import React from 'react';
import {useFetch} from 'use-http';
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis,} from 'recharts';


const HealthCharts = props => {
    const {loading, error, data = []} = useFetch('/health/stats', {cachePolicy: 'no-cache'}, [props.refreshData])
    if (loading) return <div>Loading...</div>
    if (data.length === 0) return <div>No Data</div>

    const manualData = [
        {
            date: "2022-01-01",
            heart_rate: 85,
            weight: 85,
        },
        {
            date: "2022-01-02",
            heart_rate: 86,
            weight: 80,
        }
    ]

    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis allowDataOverflow dataKey="date"/>
                <Tooltip/>
                <Legend/>
                <Line type="monotone" dataKey="heart_rate" stroke="#8884d8"/>
                <Line type="monotone" dataKey="weight" stroke="#82ca9d"/>
            </LineChart>
        </ResponsiveContainer>
        // </div>
    )
}

export default HealthCharts;
