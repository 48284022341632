import '../sass/project.scss';
import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './src/App';

const rootElement = document.getElementById("app");

if(rootElement){
    const root = createRoot(rootElement);
    root.render(<App/>);
}
