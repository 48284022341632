import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {useFetch} from 'use-http';

const HealthForm = props => {
    const {get, post, del, response, error, loading} = useFetch();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let data = Object.fromEntries(formData.entries());
        if (data.weight) {
            await post('/health/weights', {weight: data.weight});
        }
        if (data.heartRate) {
            await post('/health/heartrates', {heart_rate: data.heartRate});
        }
        props.triggerRefresh()
        props.handleCloseModal()
    }
    return (
        <Form onSubmit={handleSubmit}>
            <FloatingLabel
                className="mb-3"
                controlId="floatingWeight"
                label="Weight">
                <Form.Control name="weight" type="number" step="0.1" placeholder="Weight" min={40} max={150}/>
            </FloatingLabel>
            <FloatingLabel
                className="mb-3"
                controlId="floatingHeartRate"
                label="Heart-Rate">
                <Form.Control name="heartRate" type="number" placeholder="Heart-Rate"/>
            </FloatingLabel>
            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    );
};


const HealthModal = props => {
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Health Data Entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HealthForm triggerRefresh={props.triggerRefresh} handleCloseModal={props.handleCloseModal}/>
            </Modal.Body>
        </Modal>
    )
}
export default HealthModal;
