import React from 'react';

const NotFound = () => (
  <div>
    <h1>404 Not Found</h1>
    <p>The page you're looking for could not be found.</p>
  </div>
);

export default NotFound;
