import React, {useEffect, useState} from 'react';
import ActivitiesTable from '../components/activitiesTable';
import {useFetch} from 'use-http';
import ActivitiesHeatMap from "../components/heatMap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import HealthCharts from "../components/charts";

const HomePage = props => {
    const [activitiesData, setActivitiesData] = useState([])
    const {get, post, response, error, loading} = useFetch({cachePolicy: 'no-cache'});

    useEffect(() => {
        loadActivities()
    }, [props.refreshData]) // componentDidMount
    async function loadActivities() {
        const data = await get('/activities/recent')
        if (response.ok) setActivitiesData(data)
    }


    return (
        <div>
            <h1>Recent Activities</h1>
            <hr/>
            <Row className={"gy-5"}>
                <Col xs={12}>
                    <ActivitiesTable loading={loading} refreshData={props.refreshData} data={activitiesData}/>
                </Col>
            </Row>
            <h3>Past Activities</h3>
            <Row className="justify-content-md-center">
                <Col xs lg="2">
                </Col>
                <Col xs="auto" lg="8">
                    <ActivitiesHeatMap loading={loading} refreshData={props.refreshData} data={activitiesData}/>
                </Col>
                <Col xs lg="2">
                </Col>
            </Row>
            <h3>Health</h3>
            <Row className="justify-content-md-center">
                <Col xs={12}>
                    <HealthCharts refreshData={props.refreshData}/>
                </Col>
            </Row>
        </div>
    )
        ;
}

export default HomePage;
